.sharefamily-textarea{
    margin: 30px 10px;
    font-size: larger;
    text-align: center;
}

.sharefamily-prevnextbuttons-container{
    display: flex;
}

.copy-message{
    font-weight: bold;
    font-style: italic;
    text-align: center;
    color: $color3;
}