.button-refreshshoppinglist-container {
    margin: auto auto;
    opacity: 1;
    /* background-color: $color3;
    bottom: $navBarHeight + 70px;
    position: fixed;
    top:$navBarHeight;
    right:15px; */
}

.button-refreshshoppinglist{
    font-size: $floatingbuttonsize;
    color: $color2;
}
