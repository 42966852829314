.additem-modal{
    position: absolute;
    bottom:$navBarHeight;
}
$maxWidth:574px;
@media (min-width: $maxWidth) {  
    .additem-modal{
        position: initial;
        bottom:initial;
    }
}

.quantity-inputarea {
    display: grid;
    grid-template-columns: 50% 50%;
}

.additem-submit-button{
    margin-top: 7px;
    float:right;
}
.quickadd-container{
    text-align: center;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

.quickadd-item{
    cursor: pointer;
    display:inline-block;
    text-align: center;
    padding: 5px 7px;
    height: 35px;
    background-color: $verylight-black;
    margin: 5px 5px;
    border-radius: 5px;
    animation: fadeIn 1.5s; 
}