  .table-line-shoppinglist{
    display:grid;
    grid-template-columns: 15% 60% 25%;
    background-color: white;
    border: 1px solid $verylight-black;
    border-radius: 25px;
    align-items: center;
    margin: 10px;
    padding: 5px 5px;

    box-shadow: 0px -25px 20px -20px $verylight-black,
                25px 0px 20px -20px $verylight-black,
                0px 25px 20px -20px $verylight-black,
                -25px 0px 20px -20px $verylight-black;
}

.checkbox-container {
    text-align: center;
}

.ingredient-quantity {
    font-style: italic;
}

.smallNoteItalic {
    color:$color2;
    font-style: italic;
    font-weight: bold;
}