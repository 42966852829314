.pickeditem-container{
    
}
.pickeditem-header{    
    display:grid;
    grid-template-columns: 60% 25%;

    i {
        font-size: 20px;
        color:$color2
    }
}

.noarticle-shoppinglist{
    margin-top: 150px;
}