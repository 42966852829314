@keyframes slideDown {
  0% {
    transform: translateY(-$headerBarHeight);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.weekmenu-container{
  margin: $headerBarHeight 0px 0px 0px;
  position:fixed;
  top:0;
  left:0;
  width: 100%;  
  background-color: $color0;    
  animation: slideDown 0.5s forwards;
  }

.weekmenu-content {
  padding: 15px;
  width: 100%;
  min-height: 200px;
  box-shadow: 0 2px 10px -7px $color1;
}

.clear-weekmenu-button{
  cursor: pointer;
  color: $minusColor;
  font-size: 35px;
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 10px;
  opacity: 0.9;
}