
.increment-buttons-area{
    display: inline-flex;
    height: 22px;
    margin-left: 25px;
    .num-pers{
        font-weight:bold;
        font-style: italic;
        margin-right: 5px;
        color: rgb(109, 109, 109);
    }
}

.nop-input-container{
    border-radius: 25%;
    border-style:none;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    background-color: $color0;
    margin-right: 10px;
    margin-left: 10px;
    input {
        all:unset;
        font-weight: bold;
    }
}

.minus-button{
    color: $minusColor;
    font-size: 17px;
}
.plus-button{
    color: $plusColor;
    font-size: 17px;
}