.table-line-meals {
    display: grid;
    grid-template-columns: 80% 20%;
    margin:15px 5px;
    padding-bottom: 10px;
    align-items: center;

    border: none;
    border-bottom: 1px solid $color2;
    border-radius: 10px
  }

.table-line-checkbox {
display: grid;
grid-template-columns: 15% 85%;
}

.button-view{
  outline: unset;
  border-style:none;  

  color: $color1;
  
  margin:auto auto;
  height:35px;
  width: 35px;
  border-radius:15%;

  background-color: $highlightColor;
  cursor: pointer;
  text-align: center;
  padding: 9px;
}
