
.home-page{
    background: url("/assets/homepage/homepage-landing.jpg");
    background-attachment: fixed;
    background-size:cover;
    section {
        padding: 30px 10px;
        margin-bottom: -1px; /* Workaround for a visual bug with background */
        margin: -1px; /* Workaround for a visual bug with background */
    }
    h1,h2{        
        font-family: $font-handwritten;
    }
}

  
#home-landing-area{
/*     background: url("/assets/homepage/banner.png");
    background-size:cover; */   
    padding-top: 100px; 
    min-height: 100vh;
    background-color: $color0;
}

.home-quickdescription-container{
    margin: 10px 5px;
    text-align: center;
    .photo-banner{
        img{
            width: 100%;

        }
    }
}

.home-quickdescription-text{
    text-align: left;
    margin: 15% 5px;
    color: $color2-darker;
}

.home-connectbutton{
    margin:50px auto;
}
.phone-image{  
    height: 450px;
}

.blank-section-background{
    height: 300px;
    background-color: $color0;
    opacity: 0.4;
}
#home-section2{
    background-color: $color0;
}

#home-section3{
    background-color: $color0;
    padding-bottom: 30px;
}

.home-advantage-container{
    display: flex;
}

.home-advantage-box{
    padding: 20px 10px;
    min-height: 350px;
    width: 250px;
    color:$color2-darker;

    border: 1px solid $verylight-black;
    border-radius: 25px;
    box-shadow: 0px -15px 20px -20px $verylight-black,
    15px 0px 20px -20px $verylight-black,
    0px 15px 20px -20px $verylight-black,
    -15px 0px 20px -20px $verylight-black;

    
    text-align: center;
    margin: 25px auto;

    p{
        padding: 10px 10px ;
    }
}

.howitworks-box{
    margin:40px auto;
    width: 60%;
    color:$color2-darker;
}
.slide-right{
    margin-right: 20px;
    text-align: right;
}

.slide-left{
    margin-left: 20px;
}

.footer-container{
    padding: 11px;
    height: 40px;
    color: $color0;
    background-color: $color2-darker;
    font-size: 11px;
}


/* NAVBAR */
.home-navbar-container{
    position:fixed;
    right: 0;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: $color0;
    height: 60px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.05);
    opacity: 1;
    z-index: 1;
}

.home-navbar-title{
    font-family: $font-titles-handwritten !important;
    margin: 10px 15px;
    font-size:24px;
    color: $color2;
}
.home-navbar-connectbutton-area{
    width: 100%;
    div{
        margin-right: 10px;
    }
    .home-navbar-connectbutton{
        width:100px
    }
}
/* GENERALS */
.big-font{
    font-size: 35px;
    line-height: 45px;

}

.home-divider{
    background-color: $color0;
    text-align: center;
    .home-divider-img{
        height: 50px;
    }
}

.font-handwritten{
    font-family: $font-handwritten;
}

/* SMALL DEVICES */
@media (max-width: 509px) { 
    .home-quickdescription-text{
        margin: 15px 5px;
    }
    /*  to deal with iOs restriction on background-attachment*/
    .blank-section-background{
        background: url("/assets/homepage/homepage-landing-alternative.jpg");
        background-size:cover;
        opacity: 1 !important;
    }
  }
