.installapp-container{
}

.installbutton-description-container{
    margin:50px 15px
}

.installapp-button-container{
    margin: 50px;
}

.ios-tuto-img {
    margin: 20px;
    max-width: 80%;
}

$modalPosition:25px;
.apple-install-modal{
    .modal-content{
        position: absolute;
        bottom: $modalPosition;
        font-size: 12px;
    }
    .img-apple-modal{        
        height: 25px;
    }
    .applemodal-plus-img{
        color: grey;
        font-size: 30px;
        margin-right: 15px;
    }
}

.apple-install-modal:after {
    content: "";
    position: absolute;
    bottom:-$modalPosition + 2px;
    left: 50%;
    transform: translateX(-50%);
    border: solid transparent;
    border-width: $modalPosition;
    border-top-color: #ffffff; /* Same as background color of the box */
  }

  .flex-div{
    display: flex;
    div{
        margin: auto 5px;
    }
  }