.joinfamily-header{
    margin: 20px 10px;
}
.joinfamily-clickonlinktext{
    text-align: center;
    margin: 50px 0px;
}
.disclaimer-joinfamily{
    font-style: italic;
    text-align: center;
    color: red;
}
.joinfamily-joinbutton-container{
    margin: 150px 0px;
}