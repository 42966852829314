.mealsdetails-modal{
    max-height: 90vh;
}
.details-modal-title-area{
    margin: 35px 20px 0px 20px;

    .btn-close{
        position: absolute;
        top:0;
        right: 0;
        margin: 10px;
    }
}

.description-area {
    margin-bottom: 30px;
}

.description-text-area{
    font-family: inherit;
    font-size: inherit;
    margin-left: 15px;
}

.button-update{
    background-color: $color2;
    color: $color0;
}

.meal-infos{
    font-family: $font-handwritten;
}
