@import "https://fonts.googleapis.com/css2?family=Nunito&display=swap";
@import "https://fonts.googleapis.com/css2?family=Mooli&display=swap";
@import "https://fonts.googleapis.com/css2?family=Handlee&display=swap";

@font-face {
  font-family: "MondayRain";
  src: url("../assets/fonts/Monday Rain.ttf");
}

$color0: #fcfcfc;
$color1: #242424; /* FONT 1 */
$color2:#45a10f; /* FONT 2 */
$color2-darker: #2f5e0e;
$color3: #e4870f;

$color4: #354c66;

$minusColor: rgb(233, 85, 85);
$plusColor: rgb(156, 206, 122);

$highlightColor: #9ACD32;
$verylight-black : rgba(0,0,0,0.10);

$font-classic:"Nunito", sans-serif;
$font-titles: "Mooli", sans-serif;
$font-titles-handwritten: "MondayRain", sans-serif;
$font-handwritten: "Handlee", sans-serif;

$navBarHeight : 90px;
$headerBarHeight:80px;
$fontSize : 13px;

* {
    margin: 0;
    padding: 0;
    box-sizing:border-box;    
  }

body{  
  background: $color0;
  margin: auto auto;
  
}

#root {
}

.page-component{
  color: $color1;
  background: $color0;
  font-size: $fontSize;
  font-family: $font-classic;
  margin-top: $headerBarHeight;
  padding-bottom: 200px;
  min-height: 100vh;

  padding-top: 15px;
}

h1{
  font-family: $font-titles-handwritten;
  color: $color2;
  font-size: 24px !important;
  text-align: center;
}
h2{
  font-family: $font-handwritten;
  color: $color2;
  font-size: 22px !important;
}

h3, h4 {
  font-family: $font-classic;
  color: $color1;
}

.font-color0{
  color: $color0
}
.font-color1{
  color: $color1
}
.font-color2{
  color: $color2 !important;
}
.font-color3{
  color: $color3 !important;
}
.back-color0{
  background-color: $color0
}
.back-color1{
  background-color: $color1
}
.back-color2{
  background-color: $color2
}
.back-color3{
  background-color: $color3
}

.font-classic{
  font-family: $font-classic;
}
.font-bold{
  font-weight: bold;
}
.hidden-element{
  visibility:hidden;
}
.error-message{
  color: red;
  font-style: italic ;
  font-size: 12px;
}
.mealstable-createrecipe-button{
  margin-left:auto auto !important;
}
.second-bottom-banner{
  top: unset;
  position: fixed;    
  bottom: $navBarHeight; 
  right: 0;
  height: 60px;
  font-size: 18px;
  width: 100%;  
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.175);

  background-color: $color2;
  color: $color0;
  text-align: center;    
  align-items: center;
  border-style: none;
  opacity: 95%;

  z-index: 10;
}
.second-bottom-banner:hover{
  background-color: $color3;
}

.round-checkbox{
  width: 30px ;
  height: 30px ;
  accent-color: $color2;
  border-radius: 50%;
  vertical-align: middle;
  border: 2px solid #ddd;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

.round-checkbox:checked{
  background-color: $color2;
}

.shoppinglist-interact-buttons{  
  position: fixed;
  top: unset;
  bottom: $navBarHeight;
  right:10px;
}
$floatingbuttonsize:30px;
.bottomright-floatingbutton-container {
  cursor: pointer;
  margin: 12px 5px;
  border-radius: 50%;
  height: $floatingbuttonsize * 1.6;    
  width: $floatingbuttonsize * 1.6;
  opacity: 0.8;
  padding-top: 4px;
  text-align: center;
}

.strikedthrough {
  text-decoration: line-through;
}

.input{
  outline: unset;
  border: 1px solid $verylight-black;
  border-radius: 25px;
  box-shadow: 0px -25px 20px -20px $verylight-black,
              25px 0px 20px -20px $verylight-black,
              0px 25px 20px -20px $verylight-black,
              -25px 0px 20px -20px $verylight-black;
  padding: 10px;
  margin: 5px 0px;
  width: 100%;
}   
.input:hover,.input:focus,.input:active,.input:focus-visible{
  outline: none;
    border: 2px solid $color3 !important;
}
.input-password{
  height: 100%;
  margin: 0px;
  border: none;
  outline: none;
}
.input-password-toggle{
  margin-left: 10px;
  font-size: 14px;
  color: grey;  
  opacity: 0.6;
}
.select-input{
  outline: unset;
  color: $color1 !important;
  background-color: white !important;
}

.text-center{
  text-align: center;
}

.centered {
  text-align:center;
}

.underlined{
  text-decoration: underline;
}
.alldir-shadows{
  box-shadow: 0px -20px 20px -20px black,
              20px 0px 20px -20px black,
              0px 20px 20px -20px black,
              -20px 0px 20px -20px black;
}

.divider{
  width:200px;
  height:3px;
  background-color: $highlightColor;
  margin:20px auto 20px auto;
  border-radius:50%;
}

.section-title-centered{
  margin: 25px auto 0px auto;
  text-align: center;
}

.info-modal-closebutton{
float:right;
}
.rounded-shorttext-button {
  text-align: center;
  padding: 10px;
  margin: 5px auto;
  width: 150px;
  border-radius: 85px;
}  
.rounded-shorttext-button:hover{
  background-color: $color3;
  cursor: pointer;
  
}

.rounded-midtext-button {
  text-align: center;
  padding: 10px;
  margin: 5px auto;
  width: 250px;
  border-radius: 85px;
}
.squared-midtext-button{
  text-align: center;
  padding: 10px;
  margin: 5px auto;
  width: 200px;
  border-radius: 5px;
}
.rounded-midtext-button:hover, .squared-midtext-button:hover{
  background-color: $color3;
  cursor: pointer;
}

/* VERY SMALL DEVICES */
@media (max-width: 360px) { 
  #root {
    font-size: 12px !important;
  }
  h1{
    font-size: 18px !important;
  }
  h2{
    font-size: 16px !important;
  }
  .meal-ingredient {
    font-size: 12px !important;
  }
  .navbar-smalltext{
    font-size: 11px !important;
  }
}
/* BIG DEVICES */
/* $maxWidth:850px;
@media (min-width: $maxWidth) {
  #root {
    width: $maxWidth;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0px -20px 20px -5px $verylight-black,
                20px 0px 20px -5px $verylight-black,
                0px 0px 0px 0px $verylight-black,
                -20px 0px 20px -5px $verylight-black;
  }
  .header-bar{    
    max-width: $maxWidth;
    right:unset !important; 
    margin-left: auto;
    margin-right: auto;
  }
  .navigation{    
    max-width: $maxWidth;
    right:unset !important; 
    margin-left: auto;
    margin-right: auto;
  }
  .second-bottom-banner{    
    max-width: $maxWidth;
    right:unset !important; 
    margin-left: auto;
    margin-right: auto;
  }

  .shoppinglist-interact-buttons{
    right:unset;
    margin-left:780px;
  }

  .header-dropdownmenu-content{

  }

  .weekmenu-container{    
    max-width: $maxWidth;
  }
} */
