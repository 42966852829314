.header-container{
    background-color: $color0;
}

.header-bar {
    height: $headerBarHeight;
    position: fixed;
    top: 0;
    right:0; 
    width: 100%;
    margin-bottom: 15px;

    display: grid;
    grid-template-columns: 60% 20% 20%;

    text-align: center;
    align-items: center;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.175);
    background-color: $color0;
}

.mwm-panel{
    cursor: pointer;
    font-size: 30px;
    color: $color2;
    animation: bounce 1.5s infinite;
}

@keyframes bounce {
    100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-8px); 
    }
}
