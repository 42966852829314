
.form-container{
    margin: 5px;
}
.recipename-input{
    font-style: italic;
    font-size: 18px;     
}

.description-input{
    min-height: 100px;
}

.nop-input {
    width: 70px;
    text-align: center;
}

.ingredientlist-input-area {
    display: grid;
    grid-template-columns: 10% 50% 20% 20%;
}

.ingredients-buttons{
    display: flex;
}
.plus-ingredient-button{
    margin-left: 10%;
    font-size: 40px;
    color: $plusColor;
    width: 15%;
    cursor: pointer;
}

.deleteitem-button{
    color: $minusColor;
    margin: auto auto;
    font-size: 20px;
}