.subscribe-input {
    margin: 20px 20px;
}
.red-bottomright-quantitybadge {
    position: absolute;
    bottom: 0;
    right:10%;
    color: red;
    font-size: 20px;
}

/* SUBSCRIBE SUCCESS PAGE */
.subscribe-success{
    margin: 100px 10px 20px 10px;
}

.subscribesuccess-infos-container{
    margin-top: 30px;
}

.duo-grid-left{
    align-items: center;
    margin: 20px 5px;
    display: flex;
}
.duo-grid-right{
    align-items: center;
    margin: 20px 5px;
    display: flex;
}

.img-tuto {
    margin: auto auto;
    max-width: 75px;
}

.subscribe-label {
    margin-top:15px;
}

/* .subscribe-buttons-area{
    display: flex;
}
.subscribe-back-btn{
    margin: auto auto;
    background-color: $color2;
    color: $color0;
    height: 40px;
    width: 40px;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
} */

.date-input-area{
    display: grid;
    grid-template-columns: 30% 30% 40%;
    .date-input-container{
        margin: 1px;
    }
    .date-label{
        text-align: center;
        margin-bottom: 0px;
        font-style: italic;
    }
    .date-input{
        outline: unset;
        color: $color1 !important;
        background-color: white !important;
        border-radius: 5px;
    }
    }