.navigation {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    top: unset;
    position: fixed;
    bottom: 0;
    right:0;
    width: 100%;        
    text-align: center;        
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.175);
    background-color: $color0;
    align-items: center;
    height: $navBarHeight;
    font-size: 20px;
    z-index: 5;
}

.nav-link-container {
    cursor: pointer;
    list-style-type: none;
    margin: 0px 5px ;
    font-size: 30px;
    color: $color2;
}
.nav-link-container.nav-active {
    background-color: black;
}
.nav-link-container:hover{
    font-weight: bold;
    color: $highlightColor;
}

.navlink{
    all: unset;
}

.navbar-smalltext{
    font-size: 13px;
    font-style: italic;
}

.nav-active{
    font-weight: bold;
    background-color: black;
    color: $color3;
    .img-w{        
        background-color:$color3;
    }
}

.img-w{
    height:45px;
    background-color:$color2;
}