.deletemodal-response-container{
    text-align: center;
    height: 100%;

    button{
        margin: 10px;
    }
}

.delete-modal-container {
    margin: auto auto;
}