.header-dropdownmenu-container{
    cursor:pointer;
    font-size: 30px;
    color: $color2;
}
.header-dropdownmenu-container:hover{    
    font-size: 35px;
}
.header-dropdownmenu-content {
    margin: $headerBarHeight 0px 0px 0px;
    position:absolute;
    top:0;
    right: 0;
    background-color: $color0;    
    animation: slideDown 0.5s forwards;
    float: right;
    text-align: right;
    box-shadow: inset 1px 1px 10px 2px $verylight-black;    
    cursor: pointer;
}
.dropdownmenu-element {
    margin: 15px;
    font-size: 20px;
}