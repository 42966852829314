.autocomplete-container{
    z-index:0;
    margin: 5px 0px;
}
.autocomplete-container:focus-within {
    z-index:10 !important;
}  
.wrapper:hover,.wrapper:focus,.wrapper:active,.wrapper:focus-visible{
  outline: none;
    border: 2px solid $color3 !important;
}