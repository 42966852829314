.reset-page{
    .login-container{
        padding-bottom: 50px;
    }
}
.login-container {
    border: 1px solid $verylight-black;
    border-radius: 25px;
    width: 250px;
    margin: 60px auto 50px auto;
    background-color: white;
}

.login-input {
    margin: 40px 20px;
}

.login-button-area {
    margin-top: -15px;
    text-align: center;
}

.subscribe-area {
    margin-top: 15px;
    margin-bottom: 30px;
    font-style: italic;
}

.password-forget-text{
    text-align: center;
    text-decoration: underline;
    font-style: italic;
    cursor: pointer;
    color: $color2-darker;
}
