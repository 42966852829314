.button-resetshoppinglist-container {
    background-color: rgb(252, 88, 88);
    bottom: $navBarHeight + 130px;
}

.button-resetshoppinglist{
    font-size: $floatingbuttonsize;
    color: $color0;
}

.confirmmodal-footer{
    display: grid;
    grid-template-columns: 50% 50%;
}